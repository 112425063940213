<mat-drawer-container class="at-sidenav">
  <mat-drawer mode="side" opened [disableClose]="true" class="dark:bg-darkgray">
    <mat-toolbar class="bg-darkblue">
        <img src="{{companyLogo}}" alt="RINA" style="height: 42px; width: 40px" class="mx-auto my-0">
    </mat-toolbar>
    <rina-sidenav [items]="modules$ | async"></rina-sidenav>
    <div class="flex flex-col justify-end items-center content-center" [style.margin]="'50px 0px 0px'">
      <a href="https://www.rina.org" target="_blank" title="RINA S.P.A.">
        <img *ngIf="(theme$ | async) === 'dark'; else lightLogo" alt="rina-logo"
             #darkLogo
             style="height: 40px; width: 50px"

             src="./assets/rina/RINA-light.webp"
             (error)="darkLogo.src = './assets/rina/RINA-light.png'"
        >
        <ng-template #lightLogo>
          <img alt="rina-logo" style="height: 40px; width: 50px"
               #lightLogo
               src="./assets/rina/RINA-dark.webp"
               (error)="lightLogo.src = './assets/rina/RINA-light.png'"
          >
        </ng-template>
      </a>
      <div class="at-version dark:text-white">{{versionInfo.version}}</div>
    </div>
  </mat-drawer>
  <mat-drawer-content style="overflow: hidden">
    <mat-toolbar class="at-user-toolbar bg-lightblue dark:bg-darkgray-900" fxLayoutAlign="end">
      <button
        *ngIf="isBusinessUser$ | async"
        class="self-center ml-5"
        [matTooltip]="'Tenants and subscription details'"
        mat-icon-button [routerLink]="['/business']"
        routerLinkActive="router-active">
        <mat-icon>settings</mat-icon>
      </button>
      <rina-user-menu [user]="loggedUser$ | async"
                      [description]="(tenantInUse$ | async)?.name"
                      [availableTenants]="tenants$ | async"
                      (tenantChange)="onTenantChange($event)"
                      (doLogout)="doLogout()"
                      (goToSupport)="goToSupport()" (switchTheme)="switchTheme()"
                      (goToProfile)="goToProfile()"></rina-user-menu>
    </mat-toolbar>
    <div class="router-container">
      <cube-indeterminate-bar *ngIf="loading"></cube-indeterminate-bar>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
