import {User} from '../../models/user';
import {createReducer, on} from '@ngrx/store';
import {StoreSection} from '../../models/store-section.model';
import * as UsersActions from './users.actions';
import * as UserGroupsActions from '../user-groups/user-groups.actions';
import {add, remove, update} from '../reducers';

export const initialState: StoreSection<User> = {};

export const usersReducer = createReducer(
  initialState,
  on(UsersActions.FetchUsersSuccess, (state, {users}) => fetchUsers(users)),
  on(UsersActions.AddUserSuccess, (state, {user}) => add(state, user)),
  on(UsersActions.UpdateUserSuccess, (state, {userName, user}) => update<User>(state, userName, user)),
  on(UsersActions.DeleteUserSuccess, (state, {user}) => remove(state, user)),
  on(UserGroupsActions.UserGroupsReset, () => onReset()),
);
const onReset = () => ({});
const fetchUsers = (users: User[]) => users.reduce(
  (userMap, user) => ({...userMap, [user.name]: user}),
  initialState
);
