import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttpClient} from './api-http-client';
import {NodeLabels, TenantLabels} from '../../models/node-labels';
import {LinkLabels, LinkLabelsObject, LinkLabelsSubject} from '../../models/link-labels';
import {CURRENT_TENANT} from '../../models/tenant.model';
import {Neighbours} from '../../models/link.model';
import {PlatformFeature} from '../../models/feature.model';

@Injectable({providedIn: 'root'})
export class ManagerApiService extends ApiHttpClient {
  constructor() {
    super();
  }

  public getSelectedBySolution(platform: string, solution: string, role: string, type: 'measureCategories' | 'feature' | string): Observable<any[]> {
    return this.get(`/tenants/${CURRENT_TENANT}/roles/${role}/platforms/${platform}/solutions/${solution}/${type}`);
  }

  public getTenantFeatures(tenant = CURRENT_TENANT): Observable<PlatformFeature[]> {
    return this.get<PlatformFeature[]>(`/tenants/${tenant}/features`);
  }

  public getTenantResources(tenant = CURRENT_TENANT, domain?: NodeLabels[]): Observable<any[]> {
    return this.get(`/tenants/${tenant}/resources?${domain?.length ? 'domains=' + domain?.join(',') : ''}`);
  }

  // --- Platform API ---

  public getTenantNodes<T>(node: TenantLabels, tenant = CURRENT_TENANT): Observable<T> {
    return this.get<T>(`/tenants/${tenant}/${node}`);
  }

  // --- Node API ---
  /**
   * Returns the nodes proprieties or the group details
   * @param nodeLabel Node label in the format of @link NodeLabels
   * @param nodeId if added ask for single node detail
   * @param tenant add custom tenant
   */
  public getNodeProperties<T>(nodeLabel: NodeLabels, nodeId?: string, tenant = CURRENT_TENANT): Observable<T | T[]> {
    return this.get<T>(`/tenants/${tenant}/nodes/${nodeLabel}${nodeId ? '/' + nodeId : ''}`);
  }

  public postNodeProperties<T>(nodeLabel: NodeLabels, nodeId: string, node: T, tenant = CURRENT_TENANT): Observable<unknown> {
    return this.post<T>(`/tenants/${tenant}/nodes/${nodeLabel}/${nodeId}`, node);
  }

  public putNodeProperties<T>(nodeLabel: NodeLabels, nodeId: string, node: T, tenant = CURRENT_TENANT): Observable<unknown> {
    return this.put<T>(`/tenants/${tenant}/nodes/${nodeLabel}/${nodeId}`, node);
  }

  public deleteNodeProperties(nodeLabel: NodeLabels, nodeId: string, tenant = CURRENT_TENANT): Observable<unknown> {
    return this.delete<unknown>(`/tenants/${tenant}/nodes/${nodeLabel}/${nodeId}`);
  }

  /**
   * Returns a list of neighbours nodes, grouped by label, including their properties.
   * @param nodeLabel Available values : users, userGroups, asset, assetGroups, roles
   * @param nodeId The node name.
   * @param tenant the tenant DEFAULT CURRENT
   */
  public getNodeNeighboursProperties(nodeLabel: NodeLabels, nodeId: string, tenant = CURRENT_TENANT): Observable<Neighbours> {
    return this.get<Neighbours>(`/tenants/${tenant}/neighbours/${nodeLabel}/${nodeId}`);
  }

  /**
   * Returns a list of resources (nodes), grouped by label, including their properties.
   * Resources include neighbours and node linked to groups this node belongs to or manages.
   * In other words, this endpoint returns all the resources this node can access to.
   * @param nodeLabel Available values : users, userGroups, asset, assetGroups, roles
   * @param nodeId The node name.
   * @param tenant the tenant DEFAULT CURRENT
   */
  public getNodeCompositeProperties(nodeLabel: NodeLabels, nodeId: string, tenant = CURRENT_TENANT): Observable<Neighbours> {
    return this.get<Neighbours>(`/tenants/${tenant}/composite/${nodeLabel}/${nodeId}`);
  }

  // // --- Link API ---
  // public postNodesLink(linkLabel: LinkLabels.Belongs, subject: Asset, object: AssetGroup): Observable<unknown>;
  // public postNodesLink(linkLabel: LinkLabels.CanUse, subject: Role, object: Feature): Observable<unknown>;
  // public postNodesLink(linkLabel: LinkLabels.Has, subject: User | UserGroup, object: Role): Observable<unknown>;
  // public postNodesLink(linkLabel: LinkLabels.Manages, subject: User | UserGroup, object: Asset | AssetGroup): Observable<unknown>;
  // public postNodesLink(linkLabel: LinkLabels.Observes, subject: Role, object: Measure): Observable<unknown>;
  public postNodesLink(
    linkLabel: LinkLabels,
    subject: LinkLabelsSubject,
    object: LinkLabelsObject,
    tenant = CURRENT_TENANT): Observable<unknown> {
    return this.post<unknown>(`/tenants/${tenant}/links/${linkLabel}/${subject.name}/${object.name}`);
  }

  // public deleteNodesLink(linkLabel: LinkLabels.Belongs, subject: Asset, object: AssetGroup): Observable<unknown>;
  // public deleteNodesLink(linkLabel: LinkLabels.CanUse, subject: Role, object: Feature): Observable<unknown>;
  // public deleteNodesLink(linkLabel: LinkLabels.Has, subject: User | UserGroup, object: Role): Observable<unknown>;
  // public deleteNodesLink(linkLabel: LinkLabels.Manages, subject: User | UserGroup, object: Asset | AssetGroup): Observable<unknown>;
  // public deleteNodesLink(linkLabel: LinkLabels.Observes, subject: Role, object: Measure): Observable<unknown>;
  public deleteNodesLink(linkLabel: LinkLabels, subject: LinkLabelsSubject,
                         object: LinkLabelsObject, tenant = CURRENT_TENANT): Observable<unknown> {
    return this.delete<unknown>(`/tenants/${tenant}/links/${linkLabel}/${subject.name}/${object.name}`);
  }
}
