import {DefaultDataServiceConfig, EntityMetadataMap} from '@ngrx/data';
import {Tenant} from '../models/tenant.model';
import {environment} from '../../environments/environment';
import {Subscription} from '../models/subsciption.model';

export const entityMetadata: EntityMetadataMap = {
  tenants: {
    selectId: (tenant: Tenant) => tenant.name,
  },
  subscriptions: {
    selectId: (subscriptions: Subscription) => subscriptions.name,
  },
};
export const pluralNames = {
  // Case matters. Match the case of the entity name.
  tenants: 'tenants',
  subscriptions: 'subscriptions'
};
export const adminToolDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.api.baseUrl
};
export const entityConfig = {
  entityMetadata,
  pluralNames
};
