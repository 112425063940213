import {Component, Input, ViewEncapsulation} from '@angular/core';
import {SidenavItem} from '../sidenav.component';

@Component({
  selector: 'rina-sidenav-item',
  template: `
    <div
      *hasFeature="item?.feature" [routerLink]="[item?.link]"
      class="
      menu-item
      cursor-pointer
      flex flex-col p-3 content-center items-center
      box-border
      dark:text-grayweb"
      routerLinkActive="active">
        <mat-icon>{{ item?.icon }} </mat-icon>
        <span style="font-size: .8em">{{item?.name}}</span>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class SidenavItemComponent {
  @Input() item: SidenavItem | undefined;
}
