import {Injectable} from '@angular/core';
import {ApiHttpClient} from './api-http-client';
import {Observable} from 'rxjs';
import {User} from '../../models/user';
import {Asset} from '../../models/asset.model';
import {Feature} from '../../models/feature.model';
import {CURRENT_TENANT, Tenant} from '../../models/tenant.model';

@Injectable({providedIn: 'root'})
export class UsersApiService extends ApiHttpClient {
  constructor() {
    super();
  }

  // --- Roles API ---

  public getRoleFeatures(platform: string, role: string, tenant = CURRENT_TENANT): Observable<Feature[]> {
    return this.get<Feature[]>(`/tenants/${tenant}/roles/${role}/platforms/${platform}/features`);
  }

  public getRoleFeaturesBySolution(platform: string, role: string, solution: string, tenant = CURRENT_TENANT): Observable<Feature[]> {
    return this.get<Feature[]>(`/tenants/${tenant}/roles/${role}/platforms/${platform}/solutions/${solution}/features`);
  }

  // tslint:disable-next-line:max-line-length
  public getRoleMeasureCategoriesBySolution(platform: string, role: string, solution: string, tenant = CURRENT_TENANT): Observable<unknown> {
    return this.get<unknown>(`/tenants/${tenant}/roles/${role}/platforms/${platform}/solutions/${solution}/measureCategories`);
  }

  // --- Users API ---

  public getUserProfile(user: string, tenant = CURRENT_TENANT): Observable<User> {
    return this.get<User>(`/tenants/${tenant}/users/${user}/profile`);
  }

  getUserFeatures(user: string, tenant = CURRENT_TENANT): Observable<Feature[]> {
    return this.get<Feature[]>(`/tenants/${tenant}/users/${user}/features`);
  }

  // public searchAssets(tenant = CURRENT_TENANT, user: string, assetGroup: string, search: string): Observable<Asset[]> {}

  // public searchAssetGroups(tenant = CURRENT_TENANT, user: string, assetGroup: string, search: string): Observable<AssetGroup[]> {}

  public getUserTenants(user: string): Observable<Tenant[]> {
    return this.get<Tenant[]>(`/users/${user}/tenants`);
  }

  // --- Assets API ---

  public getAssetGroupAssets(assetGroup: string, tenant = CURRENT_TENANT): Observable<Asset[]> {
    return this.get<Asset[]>(`/tenants/${tenant}/assetGroups/${assetGroup}/assets`);
  }
}
