import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, retry, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {keysToSnake, objectizeSnakeToCamelCase} from '../../shared/utils/case-converter';
import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class ApiHttpClient {
  protected baseUrl = environment.api.baseUrl;

  protected headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });
  protected http: HttpClient;
  protected snackBar: MatSnackBar;

  constructor() {
    this.http = inject(HttpClient);
    this.snackBar = inject(MatSnackBar);
  }

  get<T>(path: string = '', options?: any): Observable<T> {
    return this.http.get(this.baseUrl + path, {headers: this.headers, ...options}).pipe(
      map((res: unknown) => objectizeSnakeToCamelCase(res) as T),
      retry(2),
      catchError(this.handleError)
    );
  }

  post<T>(path: string = '', body: T = {} as T): Observable<T> {
    return this.http.post(this.baseUrl + path, keysToSnake(body), {headers: this.headers, observe: 'response'}).pipe(
      tap(res => {
        if (res.status === 204) {
          // api responds with 204 when resource are wrong, this must be handled as an error
          throw (new HttpErrorResponse({error: {message: 'not saved'}, status: res.status, statusText: res.statusText}));
        }
      }),
      tap(_ => this.handleSaveSuccess(getLastParam(path), 'added')),
      map(res => res.body as T),
      catchError(err => this.handleSaveError(err, getLastParam(path)))
    );
  }

  put<T, K = T>(path: string = '', body: unknown = {}): Observable<K> {
    return this.http.put(this.baseUrl + path, keysToSnake(body), {headers: this.headers}).pipe(
      map((res: unknown) => res as K),
      tap(_ => this.handleSaveSuccess(getLastParam(path), 'updated')),
      catchError(err => this.handleSaveError(err, body))
    );
  }

  delete<T>(path: string = ''): Observable<T> {
    return this.http.delete(this.baseUrl + path, {headers: this.headers}).pipe(
      map((res: unknown) => res as T),
      tap(_ => this.handleSaveSuccess(getLastParam(path), 'removed')),
      catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    const err = {
      status: (error?.status || '') + ': ' + (error?.statusText || ''),
      statusCode: error?.status,
      message: error?.error?.message || '',
    };
    this?.snackBar?.open(`Resource not found or you are not allowed to perform this actions`, '', {
      duration: 1400,
      panelClass: ['bg-error']
    });
    return throwError(() => err);
  }

  private handleSaveError(error: HttpErrorResponse, res: any): Observable<never> {
    const err = {
      status: error?.status + ': ' + error?.statusText || 0,
      statusCode: error?.status || '',
      message: error?.error?.message ? error.error.message : '',
    };
    console.error(res, 'not saved');
    this.snackBar.open(`✖️${res?.name || res} not saved`, '', {duration: 1400, panelClass: ['error-snackbar']});
    return throwError(() => err);
  }

  private handleSaveSuccess(title: string, action: string = 'saved'): void {
    this.snackBar.open(`${title} ${action} successfully`, '', {duration: 1400});
  }
}

const getLastParam = (url: string): string => url.substring(url.lastIndexOf('/') + 1, url.length);
