import {UserGroup} from '../../models/user-group';
import {createReducer, on} from '@ngrx/store';
import {StoreSection} from '../../models/store-section.model';
import * as UserGroupsActions from './user-groups.actions';
import {add, remove, update} from '../reducers';

export const initialState: StoreSection<UserGroup> = {};

export const userGroupsReducer = createReducer(
  initialState,
  on(UserGroupsActions.FetchUserGroupsSuccess, (state, {userGroups}) => fetchUserGroups(userGroups)),
  on(UserGroupsActions.AddUserGroupSuccess, (state, {userGroup}) => add<UserGroup>(state, userGroup)),
  on(UserGroupsActions.UpdateUserGroupSuccess, (state, {userGroupName, userGroup}) => update<UserGroup>(state, userGroupName, userGroup)),
  on(UserGroupsActions.DeleteUserGroupSuccess, (state, {userGroup}) => remove<UserGroup>(state, userGroup)),
  on(UserGroupsActions.UserGroupsReset, () => onReset()),
);
const onReset = () => ({});
const fetchUserGroups = (userGroups: UserGroup[]) => userGroups.reduce(
  (userGroupMap, userGroup) => ({...userGroupMap, [userGroup.name]: userGroup}),
  initialState
);

