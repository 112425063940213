import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AssetGroup} from '../../models/asset-group.model';
import {handleEffectError} from '../shared/effect-error.handler';
import * as AssetGroupsActions from './assets-group.action';
import {NodeLabels} from '../../models/node-labels';
import {ManagerApiService} from '../../services/api/manager.api.service';

@Injectable()
export class AssetsGroupEffects {
  fetchAssetGroups$ = createEffect(() => this.actions$.pipe(
    ofType(AssetGroupsActions.FetchAssetGroups),
    switchMap(() => this._managerApi.getNodeProperties(NodeLabels.AssetGroups).pipe(
      map(assetGroups => AssetGroupsActions.FetchAssetGroupsSuccess({assetGroups: assetGroups as AssetGroup[]})),
      catchError(handleEffectError)
    )),
    catchError(error => of(AssetGroupsActions.FetchAssetGroupsFail(error)))
  ));

  addAssetGroup$ = createEffect(() => this.actions$.pipe(
    ofType(AssetGroupsActions.AddAssetGroup),
    switchMap(({assetGroup}) => this._managerApi.postNodeProperties(NodeLabels.AssetGroups, assetGroup.name, assetGroup).pipe(
      map(_ => AssetGroupsActions.AddAssetGroupSuccess({assetGroup})),
      catchError(handleEffectError)
    )),
  ));

  updateAssetGroup$ = createEffect(() => this.actions$.pipe(
    ofType(AssetGroupsActions.UpdateAssetGroup),
    switchMap(({assetGroupName, assetGroup}) => this._managerApi.putNodeProperties(NodeLabels.AssetGroups, assetGroupName, assetGroup).pipe(
      map(_ => AssetGroupsActions.UpdateAssetGroupSuccess({assetGroupName, assetGroup})),
      catchError(handleEffectError)
    )),
  ));

  deleteAssetGroup$ = createEffect(() => this.actions$.pipe(
    ofType(AssetGroupsActions.DeleteAssetGroup),
    switchMap(({assetGroup}) => this._managerApi.deleteNodeProperties(NodeLabels.AssetGroups, assetGroup.name).pipe(
      map(_ => AssetGroupsActions.DeleteAssetGroupSuccess({assetGroup})),
      catchError(handleEffectError)
    )),
  ));

  constructor(private actions$: Actions,
              private _managerApi: ManagerApiService) {
  }
}
