import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  DefaultDataService,
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
  HttpUrlGenerator,
  MergeStrategy
} from '@ngrx/data';
import {Subscription} from '../../models/subsciption.model';
import {EntityActionOptions} from '@ngrx/data/src/actions/entity-action';
import {forkJoin, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ApiHttpClient} from '../../services/api/api-http-client';
import {environment} from '../../../environments/environment';
import {Feature} from '../../models/feature.model';
import { Tenant} from '../../models/tenant.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionDataService extends DefaultDataService<Subscription> {
  private basePlatformURL!: string;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private cache: SubscriptionService) {
    super('subscriptions', http, httpUrlGenerator);
  }

  private _activePlatform = '';

  get activePlatform(): string {
    return this._activePlatform;
  }

  set activePlatform(value: string) {
    this._activePlatform = value;
    this.basePlatformURL = `${environment.api.baseUrl}/platforms/${this.activePlatform}/${this.entityName}`;
    this.entityUrl = `${this.basePlatformURL}/`;
    this.entitiesUrl = this.basePlatformURL;
  }

  changePlatformAndUpdateSubscriptions(platform: string): Observable<Subscription[]> {
    this.activePlatform = platform;
    return this.cache.getAll();
  }

  add(entity: Tenant): Observable<any> {
    return this.http.post(`${this.entityUrl}${entity.name}`, entity);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService extends EntityCollectionServiceBase<Subscription> {
  activePlatform = 'CUBE';

  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private http: ApiHttpClient) {
    super('subscriptions', elementsFactory);
  }

  deleteSubscriptionFeature(sub: Subscription, feature: Feature): Observable<any> {
    return this.http.delete(`/platforms/${this.activePlatform}/subscriptions/${sub.name}/features/${feature.name}`).pipe(
      map(_ => ({...sub, features: sub.features?.filter(feat => feature.name !== feat.name)})),
      tap((s: Subscription) => this.updateOneInCache(s, {mergeStrategy: MergeStrategy.OverwriteChanges})));
  }
  addSubscriptionToTenant(sub: Subscription, tenant: Tenant, platform: string): Observable<any> {
    return this.http.post(`/platforms/${platform}/subscriptions/${sub.name}/tenants/${tenant.name}`);
  }
  removeSubscriptionToTenant(sub: Subscription, tenant: Tenant, platform: string): Observable<any> {
    return this.http.delete(`/platforms/${platform}/subscriptions/${sub.name}/tenants/${tenant.name}`);
  }
  addSubscriptionFeature(sub: Subscription, feature: Feature): Observable<any> {
    return this.http.post(`/platforms/${this.activePlatform}/subscriptions/${sub.name}/features/${feature.name}`).pipe(
      map(_ => ({...sub, features: [...(sub.features || []), feature]})),
      tap((s: Subscription) => this.upsertOneInCache(s, {mergeStrategy: MergeStrategy.OverwriteChanges})));
  }

  getByKeyWithFeatures(key: any, options?: EntityActionOptions): Observable<Subscription> {
    this.setLoading(true);
    this.setLoaded(false);
    return forkJoin({
      sub: super.getByKey(key, options),
      feat: this.http.get<Feature[]>(`/platforms/${this.activePlatform}/subscriptions/${key}/features`)
    }).pipe(map(res => ({
        ...res.sub,
        features: res.feat
      })),
      tap((sub: Subscription) => this.upsertOneInCache(sub, {mergeStrategy: MergeStrategy.OverwriteChanges})));
  }

  getFeaturesOfSubscription(subscription: Subscription): Observable<Subscription> {
    this.setLoading(true);
    this.setLoaded(false);
    return this.http.get<Feature[]>(`/platforms/${this.activePlatform}/subscriptions/${subscription.name}/features`).pipe(
      map(feat => ({...subscription, features: feat})),
      tap((sub: Subscription) => this.upsertOneInCache(sub, {mergeStrategy: MergeStrategy.OverwriteChanges})),
      tap(() => {
        this.setLoading(true);
        this.setLoaded(false);
      }));
  }
}
