import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from '../../../models/user';
import {Tenant} from '../../../models/tenant.model';

@Component({
  selector: 'rina-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {
  @Output() doLogout: EventEmitter<void> = new EventEmitter();
  @Output() goToProfile: EventEmitter<void> = new EventEmitter();
  @Output() switchTheme: EventEmitter<void> = new EventEmitter();
  @Output() goToSupport: EventEmitter<void> = new EventEmitter();
  @Output() toggleUserMenu: EventEmitter<void> = new EventEmitter();
  @Output() closeUserMenu: EventEmitter<void> = new EventEmitter();
  @Output() tenantChange: EventEmitter<Tenant> = new EventEmitter();

  @Input() user: User | null = null;
  @Input() description: string | undefined;
  public isMenuOpen = false;
  @Input() availableTenants: any;
  constructor() {
  }
}
