import {Injectable} from '@angular/core';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {User} from '../models/user';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';
import {take} from 'rxjs/operators';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
  constructor(protected router: Router, protected keycloak: KeycloakService, private store: Store<AppState>) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (!this.authenticated) {
      await this.keycloak.login({ redirectUri: window.location.origin + state.url });
    }
    const auth = await this.store.select('auth').pipe(take(1)).toPromise();
    const roles = route.data.roles as string[];
    return this._isUserGranted(auth?.user, roles);
  }

  // TODO: Add role checking
  private _isUserGranted(user: User | null | undefined, roles: string[]): boolean {
    return !!user;
  }
}
