import {Injectable} from '@angular/core';
import { Tenant} from '../../models/tenant.model';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, MergeStrategy} from '@ngrx/data';
import {ApiHttpClient} from '../../services/api/api-http-client';
import {Observable, of, switchMap} from 'rxjs';
import {Subscription} from '../../models/subsciption.model';
import {map, tap} from 'rxjs/operators';
import {UpdateTenantInUse} from '../auth/auth.actions';
import {selectTenantInUse} from '../auth/auth.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {EntityActionOptions} from '@ngrx/data/src/actions/entity-action';

@Injectable({providedIn: 'root'})
export class TenantsService extends EntityCollectionServiceBase<Tenant> {
  constructor(elementsFactory: EntityCollectionServiceElementsFactory, private _http: ApiHttpClient, private state: Store<AppState>) {
    super('tenants', elementsFactory);
  }

  getAll(options?: EntityActionOptions): Observable<Tenant[]> {
    return this._http.get(`/tenants`)
      .pipe(tap(tenants => this.addAllToCache(tenants as Tenant[])), switchMap(tenants => of(tenants as Tenant[])));
  }

  add(entity: Tenant): Observable<any> {
    this.addOneToCache(entity);
    return this._http.post(`/tenants/${entity.name}`, entity);
  }

  getSubscriptionsOfTenant(tenant: Tenant): Observable<Tenant> {
    return this._http.get<Subscription[]>(`/tenants/${tenant.name}/subscriptions`).pipe(map((sub: any) => ({
        ...tenant,
        platforms: sub
      })),
      tap((t: Tenant) => this.upsertOneInCache(t, {mergeStrategy: MergeStrategy.OverwriteChanges})),
      tap(() => {
        this.setLoading(false);
        this.setLoaded(false);
      }));
  }

  setActive(tenant: Tenant): void {
    this.state.dispatch(UpdateTenantInUse({tenant}));
  }

  selectActive(): Observable<Tenant | null> {
    return this.state.select(selectTenantInUse());
  }
}
