import {Platform} from './platform.model';
import {NamedEntity} from './named-entity';
import {HttpContextToken} from '@angular/common/http';

export interface Tenant {
  id?: string;
  name: string;
  info?: string;
  platforms?: Platform[];
  subscription?: NamedEntity[];
}

export const CURRENT_TENANT = 'current';
