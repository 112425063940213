import {Injectable} from '@angular/core';
import {
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import {LoadingService} from '../services/loading.service';
import {catchError, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(
    private _loading: LoadingService
  ) {
  }

  // tslint:disable-next-line:max-line-length
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any | HttpSentEvent | HttpHeaderResponse | HttpResponse<any> | HttpProgressEvent | HttpUserEvent<any>> {
    this._loading.setLoading(true, request.url);
    return next.handle(request)
      .pipe(catchError((err) => {
        this._loading.setLoading(false, request.url);
        return err;
      }))
      .pipe(tap(evt => {
        if (evt instanceof HttpResponse) {
          this._loading.setLoading(false, request.url);
        }
      }));
  }
}
