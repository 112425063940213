import {createReducer, on} from '@ngrx/store';
import {Asset} from '../../models/asset.model';
import {AssetReset, CreateAssetSuccess, DeleteAssetSuccess, FetchAssetsSuccess, UpdateAssetsSuccess} from './asset.action';
import {StoreSection} from '../../models/store-section.model';
import {add, remove, update} from '../reducers';

type AssetState = StoreSection<Asset>;

export const initialState: AssetState = {};


export const assetReducer = createReducer(
  initialState,
  on(FetchAssetsSuccess, (state, {assets}) => onReadAssetSuccess(assets)),
  on(CreateAssetSuccess, (state, {asset}) => add<Asset>(state, asset)),
  on(UpdateAssetsSuccess, (state, {assetName, asset}) => update<Asset>(state, assetName, asset)),
  on(DeleteAssetSuccess, (state, {asset}) => remove<Asset>(state, asset)),
  on(AssetReset, () => onAssetRest()),
);
const onAssetRest = () => ({});

const onReadAssetSuccess = (assets: Asset[]) => assets.reduce(
  (assetsMap, asset) => ({...assetsMap, [asset.name]: asset}),
  initialState
);

