export enum NodeLabels {
  Users = 'users',
  UserGroups = 'userGroups',
  Assets = 'assets',
  AssetGroups = 'assetGroups',
  Roles = 'roles'
}
export enum TenantLabels {
  Features = 'features',
  Measures = 'measureCategories',
}
