import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {Store, StoreModule} from '@ngrx/store';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {KeycloakAngularModule} from 'keycloak-angular';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {AppState, Effects, Reducers, StoreDevToolsConfig} from './store/app.state';
import {UserLogin} from './store/auth/auth.actions';
import {takeWhile} from 'rxjs/operators';
import {selectCompanyTenant, selectLoggedUser} from './store/auth/auth.selectors';
import {AppRoutingModule} from './router/app-routing.module';
import {CoreModule} from './shared/modules/core.module';
import {TenantInterceptor} from './interceptor/tenant.interceptor';
import {IndeterminateBarModule} from '@cube/lib-common';
import {DefaultDataServiceConfig, EntityDataModule, EntityServices} from '@ngrx/data';
import {adminToolDataServiceConfig, entityConfig} from './store/app-entity-metadata';
import {TenantsService} from './store/tenants/tenants.service';
import {ApiHttpClient} from './services/api/api-http-client';
import {Tenant} from './models/tenant.model';
import {EntityStoreModule} from './store/entity-store/entity-store.module';
import {LoadingInterceptor} from './interceptor/loading.interceptor';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {SideNavModule} from './shared/components/sidenav/side-nav.module';
import {UserMenuModule} from './shared/components/user-menu/user-menu.module';
import {FlexModule} from '@angular/flex-layout';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    KeycloakAngularModule,
    AppRoutingModule,
    StoreModule.forRoot(Reducers),
    EffectsModule.forRoot(Effects),
    StoreDevtoolsModule.instrument(StoreDevToolsConfig),
    IndeterminateBarModule,
    MatSnackBarModule,
    EntityStoreModule,
    EntityDataModule.forRoot(entityConfig),
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    SideNavModule,
    UserMenuModule,
    FlexModule,
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: (store: Store<AppState>) => () => {
      store.dispatch(UserLogin());
      return store.select(selectLoggedUser()).pipe(takeWhile(user => !user));
    },
    deps: [Store],
    multi: true
  }, {
    provide: APP_INITIALIZER,
    useFactory: (store: Store<AppState>) => () =>
      store.select(selectCompanyTenant()).pipe(takeWhile(tenants => !(tenants.company?.name && tenants.activeTenant?.name), true)),
    deps: [Store],
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: TenantInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true
  },
    {
      provide: DefaultDataServiceConfig,
      useValue: adminToolDataServiceConfig
    },
    ApiHttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private entityServices: EntityServices, ts: TenantsService) {
    this.entityServices.registerEntityCollectionService<Tenant>(ts);
  }
}
