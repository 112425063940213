import {createAction, props} from '@ngrx/store';
import {User} from '../../models/user';
import {Role} from '../../models/role.model';
import {Tenant} from '../../models/tenant.model';
import {Platform} from '../../models/platform.model';
import {Solution} from '../../models/solution.model';
import {Feature} from '../../models/feature.model';


export const UserLogin = createAction('[Auth] User login');
export const UserLoginSuccess = createAction('[Auth] User login success', props<{ user: User, token: string }>());
export const UserLoginFail = createAction('[Auth] User login fail', props<{ error: Error }>());

export const UserLogout = createAction('[Auth] User logout');
export const UserLogoutSuccess = createAction('[Auth] User logout success');
export const UserLogoutFail = createAction('[Auth] User logout fail', props<{ error: Error }>());

export const TokenUpdate = createAction('[Auth] Token update');
export const TokenUpdateSuccess = createAction('[Auth] Token update success', props<{ token: string }>());
export const TokenUpdateFail = createAction('[Auth] Token update fail', props<{ error: Error }>());

export let UpdateIsBusinessUser = createAction('[Auth] Update user business permission', props<{ isBusiness: boolean }>());

export const FetchUserRoles = createAction('[Auth] Fetch user roles', props<{ user: User }>());
export const FetchUserRolesSuccess = createAction('[Auth] Fetch user roles success', props<{ roles: Role[] }>());
export const FetchUserRolesFail = createAction('[Auth] Fetch user roles fail', props<{ error: Error }>());

export const FetchUserTenants = createAction('[Auth] Fetch user Tenants', props<{ user: User }>());
export const FetchUserTenantsSuccess = createAction('[Auth] Fetch user Tenants success', props<{ tenants: Tenant[] }>());
export const FetchUserTenantsFail = createAction('[Auth] Fetch user Tenants fail', props<{ error: Error }>());

export const FetchUserFeatures = createAction('[Auth] Fetch user Features', props<{ user: User }>());
export const FetchUserFeaturesSuccess = createAction('[Auth] Fetch user Features success', props<{ features: Feature[] }>());
export const FetchUserFeaturesFail = createAction('[Auth] Fetch user Features fail', props<{ error: Error }>());

export const UpdateTenantInUse = createAction('[Auth] Update tenant', props<{ tenant: Tenant }>());
export const UpdateTenantSuccess = createAction('[Auth] Update tenant success', props<{ tenant: Tenant }>());

export const UpdatePlatform = createAction('[Auth] Update platform', props<{ platform: Platform }>());
export const NotUpdatePlatform = createAction('[Auth] Not update platform');
export const UpdatePlatformFail = createAction('[Auth] Update platform fail', props<{ error: Error }>());

export const UpdateSolution = createAction('[Auth] Update solution', props<{ solution: Solution }>());
export const NotUpdateSolution = createAction('[Auth] Not update solution');
export const UpdateSolutionFail = createAction('[Auth] Update solution fail', props<{ error: Error }>());
