import {createAction, props} from '@ngrx/store';
import {Role} from '../../models/role.model';

export const RoleReset = createAction('[Roles] Reset Roles');

// Create roles
export const CreateRole = createAction(
  '[Roles] Create Role',
  props<{ roles: Role }>()
);
export const CreateRoleSuccess = createAction(
  '[Roles] Create Role Success',
  props<{ roles: Role }>()
);

// Read Roles
export const FetchRoles = createAction(
  '[Roles] Read Roles'
);
export const FetchRolesSuccess = createAction(
  '[Roles] Read Roles Success',
  props<{ roles: Role[] }>()
);
export const FetchRolesFail = createAction(
  '[Roles] Read Roles Fail',
  props<{ error: Error }>()
);

// Update Role
export const UpdateRoles = createAction(
  '[Roles] Update Roles',
  props<{ rolesName: string, roles: Role }>()
);
export const UpdateRolesSuccess = createAction(
  '[Roles] Update Roles Success',
  props<{ rolesName: string, roles: Role }>()
);

// Delete Role
export const DeleteRole = createAction(
  '[Roles] Delete Role',
  props<{ roles: Role }>()
);
export const DeleteRoleSuccess = createAction(
  '[Roles] Delete Role Success',
  props<{ roles: Role }>()
);
