import {createAction, props} from '@ngrx/store';
import {User} from '../../models/user';

export const UsersReset = createAction('[Users] Reset users');


export const FetchUsers = createAction('[Users] Fetch users');
export const FetchUsersSuccess = createAction('[Users] Fetch users success', props<{ users: User[] }>());
export const FetchUsersFail = createAction('[Users] Fetch users fail', props<{ error: Error }>());

export const AddUser = createAction('[Users] Add user', props<{ user: User }>());
export const AddUserSuccess = createAction('[Users] Add user success', props<{ user: User }>());
export const AddUserFail = createAction('[Users] Add user fail', props<{ error: Error }>());

//  TODO: Change the parameter, from "user: User" to "userId: string"
export const UpdateUser = createAction('[Users] Update user', props<{ userName: string, user: User }>());
export const UpdateUserSuccess = createAction('[Users] Update user success', props<{ userName: string, user: User }>());
export const UpdateUserFail = createAction('[Users] Update user fail', props<{ error: Error }>());

export const DeleteUser = createAction('[Users] Delete user', props<{ user: User }>());
export const DeleteUserSuccess = createAction('[Users] Delete user success', props<{ user: User }>());
export const DeleteUserFail = createAction('[Users] Delete user fail', props<{ error: Error }>());
