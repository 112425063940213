import {createAction, props} from '@ngrx/store';
import {UserGroup} from '../../models/user-group';

export const UserGroupsReset = createAction('[User Groups] Reset users groups');

export const FetchUserGroups = createAction('[User Groups] Fetch user groups');
export const FetchUserGroupsSuccess = createAction('[User Groups] Fetch users groups success', props<{ userGroups: UserGroup[] }>());
export const FetchUserGroupsFail = createAction('[User Groups] Fetch users groups fail', props<{ error: Error }>());

export const AddUserGroup = createAction('[User Groups] Add user group', props<{ userGroup: UserGroup }>());
export const AddUserGroupSuccess = createAction('[User Groups] Add user group success', props<{ userGroup: UserGroup }>());

export const UpdateUserGroup = createAction('[User Groups] Update user group', props<{ userGroupName: string, userGroup: UserGroup }>());
// tslint:disable-next-line:max-line-length
export const UpdateUserGroupSuccess = createAction('[User Groups] Update user group success', props<{ userGroupName: string, userGroup: UserGroup }>());

export const DeleteUserGroup = createAction('[User Groups] Delete user group', props<{ userGroup: UserGroup }>());
export const DeleteUserGroupSuccess = createAction('[User Groups] Delete user group success', props<{ userGroup: UserGroup }>());
