import {createReducer, on} from '@ngrx/store';
import {Role} from '../../models/role.model';
import {CreateRoleSuccess, DeleteRoleSuccess, FetchRolesSuccess, RoleReset, UpdateRolesSuccess} from './role.action';
import {StoreSection} from '../../models/store-section.model';
import {add, remove, update} from '../reducers';

type RoleState = StoreSection<Role>;

export const initialState: RoleState = {};

export const roleReducer = createReducer(
  initialState,
  on(FetchRolesSuccess, (state, {roles}) => onReadRoleSuccess(roles)),
  on(CreateRoleSuccess, (state, {roles}) => add<Role>(state, roles)),
  on(UpdateRolesSuccess, (state, {rolesName, roles}) => update<Role>(state, rolesName, roles)),
  on(DeleteRoleSuccess, (state, {roles}) => remove<Role>(state, roles)),
  on(RoleReset, () => onReset()),
);
const onReset = () => ({});

const onReadRoleSuccess = (roles: Role[]) => roles.reduce(
  (rolesMap, role) => ({...rolesMap, [role.name]: role}),
  initialState
);
