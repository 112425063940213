import {Component, Input} from '@angular/core';

export interface SidenavItem {
  id: string;
  icon: string;
  name: string;
  link: string;
  feature: string;
}

@Component({
  selector: 'rina-sidenav',
  template: `
    <mat-nav-list class="overflow-x-hidden flex flex-col justify-start" style="height: calc(100% - 190px);">
      <rina-sidenav-item *ngFor="let item of items" [item]="item"></rina-sidenav-item>
    </mat-nav-list>
  `
})
export class SidenavComponent {
  @Input() items: SidenavItem[] | null = [];
}
