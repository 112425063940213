import {createReducer, on} from '@ngrx/store';
import {StoreSection} from '../../models/store-section.model';
import {AssetGroup} from '../../models/asset-group.model';
import * as AssetGroupsActions from './assets-group.action';
import {add, remove, update} from '../reducers';

export const initialState: StoreSection<AssetGroup> = {};

export const AssetGroupsReducer = createReducer(
  initialState,
  on(AssetGroupsActions.FetchAssetGroupsSuccess, (state, {assetGroups}) => fetchAssetGroups(assetGroups)),
  on(AssetGroupsActions.AddAssetGroupSuccess, (state, {assetGroup}) => add<AssetGroup>(state, assetGroup)),
  on(AssetGroupsActions.UpdateAssetGroupSuccess, (state, {
    assetGroupName,
    assetGroup
  }) => update<AssetGroup>(state, assetGroupName, assetGroup)),
  on(AssetGroupsActions.DeleteAssetGroupSuccess, (state, {assetGroup}) => remove<AssetGroup>(state, assetGroup)),
  on(AssetGroupsActions.AssetGroupReset, () => onReset()),
);
const onReset = () => ({});

const fetchAssetGroups = (assetGroup: AssetGroup[]) => assetGroup.reduce(
  (AssetGroupMap, group) => ({...AssetGroupMap, [group.name]: group}),
  initialState
);
