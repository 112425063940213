import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidenavComponent} from './sidenav.component';
import {SidenavItemComponent} from './sidenav-item/sidenav-item.component';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {UserHasFeatureDirective} from '../directives/user-has-feature/user-has-feature.directive';


@NgModule({
  declarations: [SidenavComponent, SidenavItemComponent],
    imports: [
        CommonModule,
        MatListModule,
        MatIconModule,
        RouterModule,
        MatTooltipModule,
        UserHasFeatureDirective
    ],
  exports: [SidenavComponent, SidenavItemComponent]
})
export class SideNavModule { }
