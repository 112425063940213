import {createAction, props} from '@ngrx/store';
import {Asset} from '../../models/asset.model';

// Create assets
export const AssetReset = createAction(
  '[Assets] Reset Asset'
);
export const CreateAsset = createAction(
  '[Assets] Create Asset',
  props<{ asset: Asset }>()
);
export const CreateAssetSuccess = createAction(
  '[Assets] Create Asset Success',
  props<{ asset: Asset }>()
);

// Read Assets
export const FetchAssets = createAction(
  '[Assets] Read Assets'
);
export const FetchAssetsSuccess = createAction(
  '[Assets] Read Assets Success',
  props<{ assets: Asset[] }>()
);
export const FetchAssetsFail = createAction(
  '[Assets] Read Assets Fail',
  props<{ error: Error }>()
);

// Update Asset
export const UpdateAssets = createAction(
  '[Assets] Update Assets',
  props<{ assetName: string, asset: Asset }>()
);
export const UpdateAssetsSuccess = createAction(
  '[Assets] Update Assets Success',
  props<{ assetName: string, asset: Asset }>()
);


// Delete Asset
export const DeleteAsset = createAction(
  '[Assets] Delete Asset',
  props<{ asset: Asset }>()
);
export const DeleteAssetSuccess = createAction(
  '[Assets] Delete Asset Success',
  props<{ asset: Asset }>()
);

