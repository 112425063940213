import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {
  CreateRole,
  CreateRoleSuccess,
  DeleteRole,
  DeleteRoleSuccess,
  FetchRoles,
  FetchRolesFail,
  FetchRolesSuccess,
  UpdateRoles,
  UpdateRolesSuccess
} from './role.action';
import {Role} from '../../models/role.model';
import {of} from 'rxjs';
import {ManagerApiService} from '../../services/api/manager.api.service';
import {NodeLabels} from '../../models/node-labels';

@Injectable()
export class RoleEffects {
  readRole = createEffect(() => {
    return this.actions$.pipe(
      ofType(FetchRoles),
      mergeMap(() => this._managerApi.getNodeProperties<Role[]>(NodeLabels.Roles).pipe(
        map(roles => FetchRolesSuccess({roles: roles as Role[]})))),
      catchError(err => of(FetchRolesFail({error: err})))
    );
  });
  addRole = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateRole),
      mergeMap(({roles}) => this._managerApi.postNodeProperties(NodeLabels.Roles, roles.name, roles).pipe(
        map(_ => CreateRoleSuccess({roles})))));
  });
  updateRole = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateRoles),
      mergeMap(({rolesName, roles}) => this._managerApi.putNodeProperties(NodeLabels.Roles, rolesName, roles).pipe(
        map(_ => UpdateRolesSuccess({rolesName, roles}))))
    );
  });
  deleteRole = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteRole),
      mergeMap(({roles}) => this._managerApi.deleteNodeProperties(NodeLabels.Roles, roles.name).pipe(
        map(_ => DeleteRoleSuccess({roles}))))
    );
  });

  constructor(private actions$: Actions,
              private _managerApi: ManagerApiService) {
  }
}
