import {UserGroup} from '../models/user-group';
import {Asset} from '../models/asset.model';
import {AssetGroup} from '../models/asset-group.model';
import {Feature} from '../models/feature.model';
import {Role} from '../models/role.model';
import {authReducer, AuthState} from './auth/auth.reducer';
import {User} from '../models/user';
import {assetReducer} from './asset/asset.reducer';
import {AuthEffects} from './auth/auth.effects';
import {UsersEffects} from './users/users.effect';
import {AssetEffects} from './asset/asset.effects';
import {StoreDevtoolsOptions} from '@ngrx/store-devtools/src/config';
import {environment} from '../../environments/environment';
import {userGroupsReducer} from './user-groups/user-groups.reducer';
import {UserGroupsEffects} from './user-groups/user-groups.effects';
import {LayoutState} from '../models/layout-state.model';
import {layoutReducer} from './layout/layout.reducer';
import {StoreSection} from '../models/store-section.model';
import {Platform} from '../models/platform.model';
import {LayoutEffects} from './layout/layout.effects';
import {usersReducer} from './users/users.reducer';
import {AssetGroupsReducer} from './asset-group/assets-group.reducer';
import {AssetsGroupEffects} from './asset-group/assets-group.effects';
import {roleReducer} from './role/role.reducer';
import {RoleEffects} from './role/role.effects';

export interface AppState {
  auth: AuthState;
  users: StoreSection<User>;
  userGroups: StoreSection<UserGroup>;
  assets: StoreSection<Asset>;
  assetGroups: StoreSection<AssetGroup>;
  platforms: StoreSection<Platform>;
  features: StoreSection<Feature>;
  roles: StoreSection<Role>;
  layout: LayoutState;
}

export const Reducers = {
  auth: authReducer,
  users: usersReducer,
  userGroups: userGroupsReducer,
  assets: assetReducer,
  layout: layoutReducer,
  assetGroups: AssetGroupsReducer,
  roles: roleReducer
};
export const Effects = [
  AuthEffects,
  UsersEffects,
  UserGroupsEffects,
  AssetEffects,
  LayoutEffects,
  AssetsGroupEffects,
  RoleEffects
];

export const StoreDevToolsConfig: StoreDevtoolsOptions = {
  maxAge: 25,
  logOnly: environment.production
};
