import {NgModule} from '@angular/core';
import {TenantsService} from '../tenants/tenants.service';
import {EntityDataService, EntityServices} from '@ngrx/data';
import {SubscriptionDataService, SubscriptionService} from '../subscriptions/subscription.service';


@NgModule({
  imports: [],
  providers: [TenantsService] // <-- provide the data service
})
export class EntityStoreModule {
  constructor(
    entityDataService: EntityDataService,
    entityCollectionService: EntityServices,
    subData: SubscriptionDataService,
    subEntity: SubscriptionService
  ) {
    entityDataService.registerService('subscriptions', subData);
    entityCollectionService.registerEntityCollectionServices([subEntity]);
  }
}
