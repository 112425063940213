import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserMenuComponent} from './user-menu.component';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';


@NgModule({
  declarations: [UserMenuComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    FlexLayoutModule,
    MatIconModule,
    MatListModule
  ],
  exports: [UserMenuComponent]
})
export class UserMenuModule { }
