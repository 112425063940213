import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from '../guard/auth.guard';

export const MockedRole = 'admin';

const routes: Routes = [
  {
    path: 'users',
    loadChildren: () => import('../modules/table-pages/users/users.module').then(m => m.UsersModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'userGroups',
    loadChildren: () => import('../modules/table-pages/user-groups/user-groups.module').then(m => m.UserGroupsModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'assetGroups',
    loadChildren: () => import('../modules/table-pages/assets-groups/asset-groups.module').then(m => m.AssetGroupsModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'roles',
    loadChildren: () => import('../modules/table-pages/roles/roles.module').then(m => m.RolesModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'assets',
    loadChildren: () => import('../modules/table-pages/assets/assets.module').then(m => m.AssetsModule),
    runGuardsAndResolvers: 'always',
  }, {
    path: 'edit',
    loadChildren: () => import('./../modules/edit/edit.module').then(m => m.EditModule),
    runGuardsAndResolvers: 'always',
  }, {
    path: 'business',
    loadChildren: () => import('../modules/business/business.module').then(m => m.BusinessModule),
    runGuardsAndResolvers: 'always',
  }, {
    path: 'tokens',
    loadChildren: () => import('../modules/auth-tokens/auth-tokens.module').then(m => m.AuthTokensModule),
    runGuardsAndResolvers: 'always',
  },
  {path: '**', redirectTo: 'users'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {
}
