import {SidenavItem} from '../shared/components/sidenav/sidenav.component';

export const USER_MODULES: SidenavItem[] = [
  {
    id: 'users',
    name: 'Users',
    icon: 'person',
    link: '/users',
    feature: '*'
  },
  {
    id: 'userGroups',
    name: 'User Groups',
    icon: 'group',
    link: '/userGroups',
    feature: '*'

  },
  {
    id: 'assets',
    name: 'Assets',
    icon: 'all_out',
    link: '/assets',
    feature: '*'
  },
  {
    id: 'assetGroups',
    name: 'Asset Groups',
    icon: 'donut_small',
    link: '/assetGroups',
    feature: '*'
  },
  {
    id: 'roles',
    name: 'Roles',
    icon: 'admin_panel_settings',
    link: '/roles',
    feature: '*'
  },  {
    id: 'tokens',
    name: 'Tokens',
    icon: 'lock',
    link: '/tokens',
    feature: 'adt-tokens'
  }
];
