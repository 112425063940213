import {NgModule} from '@angular/core';
import {ScrollingModule} from '@angular/cdk/scrolling';

@NgModule({
  declarations: [],
  exports: [
    ScrollingModule,
  ]
})
export class CoreModule {
}
