import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AppState} from '../../../../store/app.state';
import {Store} from '@ngrx/store';
import {selectUserFeatures} from '../../../../store/auth/auth.selectors';
import {filter, take} from 'rxjs/operators';

@Directive({
  selector: '[hasFeature]',
  standalone: true
})
export class UserHasFeatureDirective {
  private feature!: string;

  @Input() set hasFeature(feature: string) {
    this.feature = feature;
    this.updateView();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>
  ) {
  }

  private updateView(): void {
    if (this.feature === '*') {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    this.store.select(selectUserFeatures()).pipe(filter(feat => !!feat), take(1))
      .subscribe(features => {
        if (JSON.stringify(features)?.toLowerCase().includes(this.feature.toLowerCase())) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }
}
