import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {AppState} from '../app.state';
import * as UserGroupsActions from './user-groups.actions';
import {UserGroup} from '../../models/user-group';
import {handleEffectError} from '../shared/effect-error.handler';
import {NodeLabels} from '../../models/node-labels';
import {ManagerApiService} from '../../services/api/manager.api.service';

@Injectable()
export class UserGroupsEffects {
  fetchUserGroups$ = createEffect(() => this.actions$.pipe(
    ofType(UserGroupsActions.FetchUserGroups),
    switchMap(() => this._managerApi.getNodeProperties(NodeLabels.UserGroups).pipe(
      map(userGroups => UserGroupsActions.FetchUserGroupsSuccess({userGroups: userGroups as UserGroup[]})),
      catchError(handleEffectError)
    )),
    catchError(error => of(UserGroupsActions.FetchUserGroupsFail(error)))
  ));

  addUserGroup$ = createEffect(() => this.actions$.pipe(
    ofType(UserGroupsActions.AddUserGroup),
    switchMap(({userGroup}) => this._managerApi.postNodeProperties(NodeLabels.UserGroups, userGroup.name, userGroup).pipe(
      map(_ => UserGroupsActions.AddUserGroupSuccess({userGroup})),
      catchError(handleEffectError)
    ))
  ));

  updateUserGroup$ = createEffect(() => this.actions$.pipe(
    ofType(UserGroupsActions.UpdateUserGroup),
    switchMap(({userGroupName, userGroup}) => this._managerApi.putNodeProperties(NodeLabels.UserGroups, userGroupName, userGroup).pipe(
      map(_ => UserGroupsActions.UpdateUserGroupSuccess({userGroupName, userGroup})),
      catchError(handleEffectError)
    ))
  ));

  deleteUserGroup$ = createEffect(() => this.actions$.pipe(
    ofType(UserGroupsActions.DeleteUserGroup),
    switchMap(({userGroup}) => this._managerApi.deleteNodeProperties(NodeLabels.UserGroups, userGroup.name).pipe(
      map(_ => UserGroupsActions.DeleteUserGroupSuccess({userGroup})),
      catchError(handleEffectError)
    ))
  ));

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private _managerApi: ManagerApiService) {
  }
}
