import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';
import {User} from '../models/user';
import {forkJoin, from, Observable, of} from 'rxjs';
import {KeycloakOptions, KeycloakService} from 'keycloak-angular';
import {environment} from '../../environments/environment';
import {switchMap} from 'rxjs/operators';
import {KeycloakProfile} from 'keycloak-js';

export interface LoginResponse {
  user: User | null;
  token: string;
}

@Injectable({providedIn: 'root'})
export class AuthService {
  private keycloakConfig: KeycloakOptions = {
    config: environment.keycloak,
    loadUserProfileAtStartUp: true,
    initOptions: {
      onLoad: 'login-required',
      checkLoginIframe: false
    },
    bearerPrefix: 'Bearer',
    bearerExcludedUrls: environment.auth.bearerExcludedUrls
  };

  constructor(private store: Store<AppState>,
              private keycloak: KeycloakService) {
  }

  public login$(): Observable<LoginResponse> {
    return from(this.keycloak.init(this.keycloakConfig)).pipe(
      switchMap(isUserLoggedIn => this._handleLoginResponse(isUserLoggedIn, this.keycloak)),
      switchMap(() => forkJoin([from(this.keycloak.loadUserProfile()) as Observable<KeycloakProfile>, from(this.keycloak.getToken())])),
      switchMap(([profile, token]) => of({user: this._createUser(profile), token}))
    );
  }

  public logout(): void {
    this.keycloak.logout(window.location.origin);
  }

  public updateToken$(): Observable<boolean> {
    return from(this.keycloak.updateToken(environment.auth.tokenLifespan));
  }

  public getToken$(): Observable<string> {
    return from(this.keycloak.getToken());
  }

  private _handleLoginResponse = (isUserLoggedIn: boolean, kcService: KeycloakService) => {
    if (isUserLoggedIn) {
      return of(isUserLoggedIn);
    } else {
      kcService.login().catch(e => console.error(e));
      throw new Error('No logged in user found');
    }
  };

  private _createUser = (profile: KeycloakProfile): User | null => {
    if (!profile.username || !profile.firstName || !profile.lastName) {
      return null;
    }
    return {
      name: profile.username,
      firstName: profile.firstName,
      lastName: profile.lastName
    };
  };
}
