import {NamedEntity} from '../models/named-entity';
import {StoreSection} from '../models/store-section.model';

export function update<T>(state: StoreSection<T>, name: string, prop: T & NamedEntity): StoreSection<T> {
  return {...remove(state, prop), [name]: prop};
}

export function add<T>(state: StoreSection<T>, prop: T & NamedEntity): StoreSection<T> {
  return ({...state, [prop.name]: prop});
}

export function remove<T>(state: StoreSection<T>, prop: T & NamedEntity): StoreSection<T> {
  const {[prop.name]: value, ...newState} = state;
  return newState;
}
