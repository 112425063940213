import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {AppState} from '../app.state';
import {User} from '../../models/user';
import {handleEffectError} from '../shared/effect-error.handler';
import * as UsersActions from './users.actions';
import {ManagerApiService} from '../../services/api/manager.api.service';
import {NodeLabels} from '../../models/node-labels';

@Injectable()
export class UsersEffects {
  fetchUsers$ = createEffect(() => this.actions$.pipe(
    ofType(UsersActions.FetchUsers),
    switchMap(() => this._managerApi.getNodeProperties<User>(NodeLabels.Users).pipe(
      map(users => UsersActions.FetchUsersSuccess({users: users as User[]})),
      catchError(handleEffectError)
    )),
    catchError(error => of(UsersActions.FetchUsersFail(error))
    )));

  addUser$ = createEffect(() => this.actions$.pipe(
    ofType(UsersActions.AddUser),
    switchMap(({user}) => this._managerApi.postNodeProperties(NodeLabels.Users, user.name, user).pipe(
      map(_ => UsersActions.AddUserSuccess({user})),
      catchError(handleEffectError)
    )),
    catchError(error => of(UsersActions.AddUserFail(error)))
  ));

  updateUser$ = createEffect(() => this.actions$.pipe(
    ofType(UsersActions.UpdateUser),
    switchMap(({userName, user}) => this._managerApi.putNodeProperties(NodeLabels.Users, userName, user).pipe(
      map(_ => UsersActions.UpdateUserSuccess({userName, user})),
      catchError(handleEffectError))
    ),
    catchError(error => of(UsersActions.UpdateUserFail(error)))
  ));

  deleteUser$ = createEffect(() => this.actions$.pipe(
    ofType(UsersActions.DeleteUser),
    switchMap(({user}) => this._managerApi.deleteNodeProperties(NodeLabels.Users, user.name).pipe(
      map(_ => UsersActions.DeleteUserSuccess({user})),
      catchError(handleEffectError)
    )),
    catchError(error => of(UsersActions.DeleteUserFail(error)))
  ));

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private _managerApi: ManagerApiService) {
  }
}
