import {createReducer, on} from '@ngrx/store';
import {LayoutState, Theme} from '../../models/layout-state.model';
import * as LayoutActions from './layout.actions';
import {environment} from '../../../environments/environment';

const getActiveTheme = (): Theme => {
  if (localStorage.getItem(environment.storageKeys.theme) === null ||
    JSON.parse(localStorage.getItem(environment.storageKeys.theme) as any)) {
    return Theme.DarkTheme;
  }
  return Theme.LightTheme;
};
export const initialState: LayoutState = {
  theme: getActiveTheme()
};

export const layoutReducer = createReducer(
  initialState,
  on(LayoutActions.SwitchTheme, (state) => switchTheme(state))
);

const switchTheme = (state: LayoutState) => ({...state, theme: getSwitchedTheme(state.theme)});

const getSwitchedTheme = (theme: Theme): Theme => {
  if (theme === Theme.LightTheme) {
    localStorage.setItem(environment.storageKeys.theme, 'true');
    return Theme.DarkTheme;
  } else {
    localStorage.setItem(environment.storageKeys.theme, 'false');
    return Theme.LightTheme;
  }
};
