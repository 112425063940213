import {createSelector} from '@ngrx/store';
import {AppState} from '../app.state';
import {AuthState} from './auth.reducer';
import {environment} from '../../../environments/environment';

const selectAuth = (state: AppState) => state.auth;

export const selectLoggedUser = () => createSelector(selectAuth, (auth: AuthState) => auth.user);
export const selectTenantInUse = () => createSelector(selectAuth, (auth: AuthState) => auth.tenant);
export const selectAllTenants = () => createSelector(selectAuth, (auth: AuthState) => auth.tenants);
export const selectCompanyTenant = () => createSelector(selectAuth, (auth: AuthState) => {
  if (auth.tenants.some(tenant => tenant.name === environment.companyTenant.name)) {
    return {
      company: environment.companyTenant,
      activeTenant: auth.tenant
    };
  } else {
    return {
      company: auth.tenant,
      activeTenant: auth.tenant
    };
  }
});
export const selectLoggedUserRoles = () => createSelector(selectAuth, (auth: AuthState) => auth.roles);
export const checkIsBusiness = () => createSelector(selectAuth, (auth: AuthState) => auth.isBusiness);
export const selectUserFeatures = () => createSelector(selectAuth, (auth: AuthState) => auth.features);
