import {Injectable} from '@angular/core';
import {ApiHttpClient} from './api-http-client';
import {Observable, ReplaySubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {FeatureResponse} from '../../models/feature.model';

@Injectable({providedIn: 'root'})
export class BusinessApiService extends ApiHttpClient{
  public platforms$: ReplaySubject<string[]> = new ReplaySubject<string[]>();

  constructor() {
  super()
  }


  public getPlatformFeatures(platform: string): Observable<FeatureResponse> {
    return this.get<FeatureResponse[]>(`/platforms/${platform}/features`).pipe(
      map(f => f[0]));
  }

  public getPlatforms(): Observable<string[]> {
    return this.get<string[]>(`/platforms`).pipe(tap(p => this.platforms$.next(p)));
  }
}
