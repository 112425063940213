import {createAction, props} from '@ngrx/store';
import {AssetGroup} from '../../models/asset-group.model';

export const AssetGroupReset = createAction('[AssetGroup] Reset Asset Group');

export const FetchAssetGroups = createAction('[Asset Groups] Fetch Asset groups');
export const FetchAssetGroupsSuccess = createAction('[Asset Groups] Fetch Assets groups success', props<{ assetGroups: AssetGroup[] }>());
export const FetchAssetGroupsFail = createAction('[Asset Groups] Fetch Assets groups fail', props<{ error: Error }>());

export const AddAssetGroup = createAction('[Asset Groups] Add Asset group', props<{ assetGroup: AssetGroup }>());
export const AddAssetGroupSuccess = createAction('[Asset Groups] Add Asset group success', props<{ assetGroup: AssetGroup }>());

// tslint:disable-next-line:max-line-length
export const UpdateAssetGroup = createAction('[Asset Groups] Update Asset group', props<{ assetGroupName: string, assetGroup: AssetGroup }>());
// tslint:disable-next-line:max-line-length
export const UpdateAssetGroupSuccess = createAction('[Asset Groups] Update Asset group success', props<{ assetGroupName: string, assetGroup: AssetGroup }>());
export const DeleteAssetGroup = createAction('[Asset Groups] Delete Asset group', props<{ assetGroup: AssetGroup }>());
export const DeleteAssetGroupSuccess = createAction('[Asset Groups] Delete Asset group success', props<{ assetGroup: AssetGroup }>());
