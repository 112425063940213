import {User} from '../../models/user';
import {createReducer, on} from '@ngrx/store';
import {Role} from '../../models/role.model';
import * as AuthActions from './auth.actions';
import {Tenant} from '../../models/tenant.model';
import {Feature} from '../../models/feature.model';

export interface AuthState {
  user: User | null;
  token: string | null;
  roles: Role[];
  isBusiness: boolean;
  tenants: Tenant[];
  tenant: Tenant | null;
  features: Feature[] | null;
}

export const initialState: AuthState = {
  user: null,
  token: null,
  roles: [],
  isBusiness: false,
  tenants: [],
  tenant: null,
  features: null
};


export const authReducer = createReducer(
  initialState,
  on(AuthActions.UserLoginSuccess, (authState, {user, token}) => userLogin(authState, user, token)),
  on(AuthActions.UserLogoutSuccess, (authState) => userLogout(authState)),
  on(AuthActions.TokenUpdateSuccess, (authState, {token}) => updateToken(authState, token)),
  on(AuthActions.FetchUserRolesSuccess, (authState, {roles}) => updateRoles(authState, roles)),
  on(AuthActions.FetchUserTenantsSuccess, (authState, {tenants}) => updateUserTenants(authState, tenants)),
  on(AuthActions.FetchUserFeaturesSuccess, (authState, {features}) => updateUserFeatures(authState, features)),
  on(AuthActions.UpdateTenantInUse, (authState, {tenant}) => updateTenant(authState, tenant)),
  on(AuthActions.UpdateIsBusinessUser, (authState, {isBusiness}) => updateBusiness(authState, isBusiness)),
);

const userLogin = (authState: AuthState, user: User, token: string) => ({...authState, user, token});

const userLogout = (_: AuthState) => ({...initialState, user: null, token: null});

const updateToken = (authState: AuthState, token: string) => ({...authState, token});

const updateRoles = (authState: AuthState, roles: Role[]) => ({...authState, roles});

const updateUserTenants = (authState: AuthState, tenants: Tenant[]) => ({...authState, tenants});

const updateUserFeatures = (authState: AuthState, features: Feature[]) => ({...authState, features});

const updateTenant = (authState: AuthState, tenant: Tenant) => ({...authState, tenant});
const updateBusiness = (authState: AuthState, isBusiness: boolean) => ({...authState, isBusiness});
