export const GLOBAL_ENVIRONMENT = {
  companyName: 'Rina',
  companyTenant: {name: 'root'},
  adminToolsRoles: {
    business: 'Business',
    manager: 'Manager',
    use: 'Ad-user'
  },
  storageKeys: {
    tenantInUse: 'current-tenant',
    theme: 'is-dark-theme'
  },
  companyLogo: 'assets/rina/CUBE_icon.svg',
  keycloak: {
    url: 'https://login.cube.rina.org/auth',
    realm: 'cube',
    clientId: 'optimum'
  },
  auth: {
    refreshingTime: 300000,
    tokenLifespan: 60,
    bearerExcludedUrls: []
  },
  api: {
    baseUrl: 'https://gw-dev.cube.rina.org/admin-tool'
  },
  supportUrl: 'https://support.cube.rina.org',
  profileUrl: 'https://login.cube.rina.org/auth/realms/cube/account/'
};
