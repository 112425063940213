import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {
  CreateAsset,
  CreateAssetSuccess,
  DeleteAsset,
  DeleteAssetSuccess,
  FetchAssets,
  FetchAssetsFail,
  FetchAssetsSuccess,
  UpdateAssets,
  UpdateAssetsSuccess
} from './asset.action';
import {Asset} from '../../models/asset.model';
import {of} from 'rxjs';
import {ManagerApiService} from '../../services/api/manager.api.service';
import {NodeLabels} from '../../models/node-labels';

@Injectable()
export class AssetEffects {
  readAsset = createEffect(() => {
    return this.actions$.pipe(
      ofType(FetchAssets),
      mergeMap(() => this._managerApi.getNodeProperties<Asset[]>(NodeLabels.Assets).pipe(
        map(assets => FetchAssetsSuccess({assets: assets as Asset[]})))),
      catchError(err => of(FetchAssetsFail({error: err})))
    );
  });
  addAsset = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateAsset),
      mergeMap(({asset}) => this._managerApi.postNodeProperties(NodeLabels.Assets, asset.name, asset).pipe(
        map(_ => CreateAssetSuccess({asset})))));
  });
  updateAsset = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateAssets),
      mergeMap(({assetName, asset}) => {
        return this._managerApi.putNodeProperties(NodeLabels.Assets, assetName, asset).pipe(
          map(_ => UpdateAssetsSuccess({assetName, asset})));
      }));
  });
  deleteAsset = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteAsset),
      mergeMap(({asset}) => this._managerApi.deleteNodeProperties(NodeLabels.Assets, asset.name).pipe(
        map(_ => DeleteAssetSuccess({asset})))));
  });

  constructor(private actions$: Actions,
              private _managerApi: ManagerApiService) {
  }
}
