import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {interval, of} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {environment} from '../../../environments/environment';
import {ManagerApiService} from '../../services/api/manager.api.service';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {NodeLabels} from '../../models/node-labels';
import * as AuthActions from './auth.actions';
import {User} from '../../models/user';
import {handleEffectError} from '../shared/effect-error.handler';
import {Role} from '../../models/role.model';
import {Neighbours} from '../../models/link.model';
import {AssetReset} from '../asset/asset.action';
import {AssetGroupReset} from '../asset-group/assets-group.action';
import {UserGroupsReset} from '../user-groups/user-groups.actions';
import {UsersReset} from '../users/users.actions';
import {UsersApiService} from '../../services/api/users.api.service';
import {Tenant} from '../../models/tenant.model';
import {RoleReset} from '../role/role.action';

@Injectable()
export class AuthEffects {
  tokenUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.TokenUpdate),
    switchMap(() => interval(environment.auth.refreshingTime)),
    switchMap(() => this.auth.updateToken$().pipe(catchError(handleEffectError))),
    switchMap(isTokenUpdated => {
      if (isTokenUpdated) {
        return this.auth.getToken$().pipe(map(token => AuthActions.TokenUpdateSuccess({token})));
      } else {
        return of(AuthActions.TokenUpdateFail({error: new Error('Cannot update the token')}));
      }
    }))
  );
  fetchUserTenants$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.FetchUserTenants),
    switchMap(action => this._userApi.getUserTenants(action.user.name)
      .pipe(catchError(handleEffectError))),
    switchMap(tenants => of(AuthActions.FetchUserTenantsSuccess({tenants: (tenants as Tenant[])}))),
    catchError(error => of(AuthActions.FetchUserRolesFail({error})))
  ));
  updateTenantInUse$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.UpdateTenantInUse),
    tap(tenant => localStorage.setItem(environment.storageKeys.tenantInUse, tenant.tenant.name)),
    mergeMap(() => [AssetReset(), RoleReset(), AssetGroupReset(), UserGroupsReset(), UsersReset()]))
  );
  updateUserTenant$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.FetchUserTenantsSuccess),
    switchMap((res: { tenants: Tenant[] }) => of(AuthActions.UpdateTenantInUse(
      {
        tenant:
          res.tenants.find(t => t.name === environment.companyTenant.name) ||
          res.tenants.find(t => t.name === localStorage.getItem(environment.storageKeys.tenantInUse)) ||
          res.tenants[0]
      }
    ))),
    catchError(error => of(AuthActions.UserLogoutFail({error})))
  ));

  fetchUserFeatures$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.FetchUserFeatures),
    switchMap(action => this._userApi.getUserFeatures(action.user.name).pipe(catchError(handleEffectError))),
    switchMap(features => of(AuthActions.FetchUserFeaturesSuccess({features}))),
    catchError(error => of(AuthActions.FetchUserFeaturesFail({error})))
  ));

  private _user!: User;
  userLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.UserLogin),
      switchMap(() => this.auth.login$().pipe(catchError(handleEffectError))),
      tap(({user}) => this._user = user as User),
      mergeMap(({user, token}) => [
        AuthActions.UserLoginSuccess({user: user as User, token}),
        AuthActions.TokenUpdate(),
        AuthActions.FetchUserTenants({user: this._user})
      ]),
      catchError(error => of(AuthActions.UserLoginFail({error})))
    );
  });

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private managerApi: ManagerApiService,
              private _userApi: UsersApiService,
              private auth: AuthService) {
  }
}
